const fixRelativeLinks = (value) => {
  // Add target blank
  value = value.replace(/http:\/\/\.\//gi, "/");

  value = value.replace(
    /<a href="http/gi,
    `<a target="_blank" rel="noreferrer" href="http`
  );

  return value;
};

export default fixRelativeLinks;

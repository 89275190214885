import React from "react";
import s from "./style.module.css";
import Main from "../../layouts/Main";
import Container from "../../components/Container";
import fixRelativeLinks from "../../helpers/fixRelativeLinks";
const copy = require(`./copy/${process.env.REACT_APP_LANG}`);

const FourZeroFour = () => {
  const { meta, title, sub, content, img } = copy;

  return (
    <Main meta={{ ...meta, robots: "noindex" }}>
      <div className={s.wrap}>
        <Container>
          <p className={s.sub}>{sub}</p>
          <h1 className={s.title}>{title}</h1>
          <p
            className={s.content}
            dangerouslySetInnerHTML={{ __html: fixRelativeLinks(content) }}
          />
          <img
            className={s.img}
            src={`${process.env.PUBLIC_URL}${img}`}
            alt=""
          />
        </Container>
      </div>
    </Main>
  );
};

export default FourZeroFour;
